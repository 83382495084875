<template>
  <div id="seller" :style="info?'padding:0px':''">
    <div class="list" v-if="!info">
      <div class="tabbar">
        <div :class="item.label===selectItem?'isAcitve':''" v-for="(item,index) in tabbarItem" :key="index" @click="clickItem(item,index)">
          {{item.label}}
        </div>
      </div>
      <div class="filter">
        <div class="select_ground">
          <div class="select_data">
            <el-select v-model="value" clearable :popper-append-to-body="false" placeholder="选择时间" @change="selectTimeChange">
              <el-option
                v-for="(item,index) in options"
                :key="index.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="select_game">
            <el-select v-model="selectGameValue" clearable :popper-append-to-body="false" placeholder="选择游戏" @change="selectGameChange" >
              <el-option
                v-for="(item,index) in selectGame"
                :key="index"
                :label="item.label"
                :value="{value:item.value,label:item.label}">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="filter_search">
          <input type="text" v-model="match" class="filter_search_input" placeholder="请输入账户关键词">
          <span class="filter_search_btn" @click="clickMatch">确认</span>
        </div>
      </div>
      <div class="product_list">
        <el-table
          :data="tableData"
          @row-click="tablaRowClick"
          style="width: 100%">
          <!-- 空数据 -->
          <template slot="empty">
            <div class="no_data">
              <img src="@/assets/image/PersonalCenter/Seller/noData.png" alt="">
            </div>
          </template>
          <!-- 商品信息 -->
          <el-table-column
            label="商品信息"
            width="570">
            <template slot-scope="scope">
              <div class="product">
                <div class="product__img">
                  <img v-if="scope.row.status===4" src="@/assets/image/PersonalCenter/Seller/sellerstatus4.png" />
                  <img v-else-if="scope.row.status===5" src="@/assets/image/PersonalCenter/Seller/sellerstatus5.png" />
                  <el-image v-else :src="scope.row.image"></el-image>
                  <!--                <img v-if="scope.row.image" :src="scope.row.image" alt="">-->
                  <!--                <img v-else src="https://images.pxb7.com/images/equip/20220214/20220214170100_361766.png" alt="">-->
                </div>
                <div class="product_info">
                  <div class="product_info_top">
                    <span>{{ scope.row.game_name }}</span>
                    {{scope.row.name}}
                  </div>
                  <div class="product_info_mid">
                  <span class="gaming_zone" v-for="(categoryItem,index) in scope.row.category" :key="index">
                    {{categoryItem.name}}：
                    <span>
                      {{categoryItem.value}}
                    </span>
                  </span>
                  </div>
                  <div class="product_info_bot">
                    <span id="release_date">发布时间：{{ scope.row.add_time|moment }}</span>
                    <span id="browse">
<!--                    <img src="@/assets/image/Home/topGamesIco.png" alt="">-->
                    热度:
                    <span>
                      {{ scope.row.click }}
                    </span>
                  </span>
                    <span id="collect">收藏：{{ scope.row.collect }}</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 金额 -->
          <el-table-column label="金额">
            <template slot-scope="scope">
              <div class="product_price">¥{{scope.row.price}}</div>
            </template>
          </el-table-column>
          <!-- 状态 -->
          <el-table-column
            label="状态"
            width="111">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag v-if="scope.row.status===1" type="warning" size="medium">在售</el-tag>
                <el-tag v-else-if="scope.row.status===6" type="warning" size="medium">出售中</el-tag>
                <el-tag v-else-if="scope.row.status===2" type="success" size="medium">已售</el-tag>
                <el-tag v-else-if="scope.row.status===3" type="info" size="medium">已下架</el-tag>
                <el-tag v-else-if="scope.row.status===4" size="medium">审核中</el-tag>
                <el-tag v-else-if="scope.row.status===5" type="danger" size="medium">审核失败</el-tag>
              </div>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column
            label=""
            width="50">
            <template >
              <div class="infoBtn" >
                <i class="el-icon-caret-right"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pages" v-if="totalCount !== 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="PageSize"
            layout="prev, pager, next, jumper"
            :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="info" v-else>
      <sell-info :product="product" :back="backClick"></sell-info>
    </div>

    <el-dialog custom-class="changeAccount"
               title="修改账号密码"
               :visible.sync="dialogFormVisible"
               width="500px"
               top="27vh"
               center>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="游戏账号:" class="dialogInput" :label-width="formLabelWidth" prop="account">
          <el-input v-model="form.account" autocomplete="off" placeholder="请输入游戏账号"></el-input>
        </el-form-item>
        <el-form-item label="游戏密码:" class="dialogInput" :label-width="formLabelWidth" prop="pwd">
          <el-input v-model="form.pwd" autocomplete="off" placeholder="请输入游戏密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" class="dialogInput" :label-width="formLabelWidth" prop="re_pwd">
          <el-input v-model="form.re_pwd" autocomplete="off" placeholder="请再次输入游戏密码"></el-input>
        </el-form-item>
        <el-form-item v-show="form.game_id == '8'" label="二级密码:" class="dialogInput" :label-width="formLabelWidth" prop="se_pwd">
          <el-input v-model="form.se_pwd" autocomplete="off" placeholder="请输入二级密码"></el-input>
        </el-form-item>
        <el-form-item v-show="form.game_id == '10' || form.game_id == '15'" label="角色密码:" class="dialogInput" :label-width="formLabelWidth" prop="se_pwd">
          <el-input v-model="form.se_pwd" autocomplete="off" placeholder="请输入角色登录密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">确认修改</el-button>
      </div>
    </el-dialog>
    <el-dialog title="求降价设置"
               custom-class="premiumSet"
               :visible.sync="premiumSet"
               width="500px"
               top="27vh"
               center>
      <el-form :model="premiumSetForm" ref="premiumSetForm">
        <el-form-item label="是否接受议价:" class="premiumSet" label-width="140px" prop="is_premium">
          <el-radio-group v-model="premiumSetForm.is_premium">
            <el-radio label="1">接受议价</el-radio>
            <el-radio label="0">不接受议价</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPForm('premiumSetForm')">确认修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import sellInfo from "@/views/PersonalCenter/Seller/sellInfo";
import {
  apiGameList,
  apiSellerAccountList,
  apiSellerReduRequest,
  apiBuyBargainStatus,
  apiProductGore,
  apiProductOff,
  apiProductOn,
  apiProductPrice,
  apiProductEditinfo,
  apiProductAccount,
  apiBargainAdd,
  apiPremiumEdit
} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {
    sellInfo
  },
  data () {
    var valiPwd = (rule, value, callback) => {
      if (value !== this.form.pwd) {
        callback(new Error('两次密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:0,
      // 默认每页显示的条数（可修改）
      PageSize:10,
      selectItem:'全部',
      selectItemIndex:0,
      //tabbar
      tabbarItem:[
        {label:'全部',value:'0'},
        {label:'在售',value:'1'},
        {label:'出售中',value:'6'},
        {label:'已售',value:'2'},
        {label:'已下架',value:'3'},
        {label:'待审核',value:'4'},
        {label:'审核失败',value:'5'},
      ],
      //账号列表
      tableData: [],
      //选择时间下拉框
      options: [
        {value: '0', label: '全部'},
        {value: '1', label: '本周内'},
        {value: '2', label: '当月'},
        {value: '3', label: '三个月内'},
        {value: '4', label: '半年内'},
        {value: '5', label: '半年以上'},
        {value: '6', label: '一年内'},
      ],
      value: '',
      //选择游戏下拉框
      selectGame: [],
      selectGameValue: '',
      //关键字搜索
      match:'',
      //修改账号密码
      account:'',
      pwd:'',
      dialogFormVisible: false,
      premiumSet:false,
      form: {
        id:'',
        game_id:'',
        account:'',
        pwd:'',
        re_pwd:'',
        se_pwd:''
      },
      rules: {
        account:[
          { required: true, message: '请输入游戏账号', trigger: 'blur' },
        ],
        pwd:[
          { required: true, message: '请输入游戏密码', trigger: 'blur' },
        ],
        re_pwd: [
          { required: true, message: '请再次输入游戏密码', trigger: 'blur' },
          { validator: valiPwd, trigger: 'blur' }
        ],
      },
      premiumSetForm:{
        is_premium:'',
        id:'',
        game_id:'',
      },
      formLabelWidth: '120px',
      //商品详情
      info:false,
      //选中的商品
      product:{}
    }
  },
  methods: {
    tablaRowClick(row, column, event){
/*      if (this.selectItemIndex !== 6) {
        if (row.status === 1 || row.status === 2) {
          this.$router.push({
            name:'GameDetails',
            query:{
              game_id:row.game_id,
              id:row.id
            }
          });
        }else{
          this.$message({
            type:'error',
            center:true,
            message:'该账号不存在或未上架'
          })
        }
      }else{
        this.$router.push({
          name:'GameDetails',
          query:{
            game_id:row.game_id,
            id:row.product_id
          }
        });
      }*/
      this.info = true
      this.product = row
    },
    //tabbar
    clickItem(item, index) {
      //获取游戏列表
      apiGameList({
      }).then(res=>{
        res.data.forEach((item,index)=>{
          let options = new Object()
          options.value = item.game_id
          options.label = item.game_name
          this.selectGame.push(options)
        })
      })
      this.selectItem = item.label
      this.selectItemIndex = item.value
      this.currentPage = 1
      this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
    },
    //table监听事件
    handleEditPrice(index, row) {
      const h = this.$createElement;
      this.$msgbox({
        title:'',
        message: h('div', {class:'confirm_content'}, [
          h('div', {class:'confirm_original_price'}, '当前价格：'+row.price+''),
          h('div',{class:'confirm_modify_bid'}, [
            h('div', {class:'confirm_modify_bid_text'}, '修改出价：'),
            h('input', {class:'confirm_modify_bid_input',attrs:{placeholder: "输入修改后的价格",id:"confirm_modify_bid_input"}}),
          ]),
        ]),
        confirmButtonText: '确认修改',
        confirmButtonClass:'change_price_btn',
        customClass: 'change_price_seller',
        closeOnClickModal:false,
        showClose:true,
        callback: action => {
          if(action === "confirm"){
            apiProductPrice({
              id:row.id,
              game_id:row.game_id,
              price:document.getElementById("confirm_modify_bid_input").value
            }).then(res=>{
              this.$message({
                message:'修改成功',
                center:true,
                type:'success'
              })
              this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
            }).catch(err=>{
              this.$message({
                message:err.msg,
                center:true,
                type:'error'
              })
            })
          }
          document.getElementById("confirm_modify_bid_input").value = ''
        }
      });
    },

    handleEditAccount(index, row) {
      this.dialogFormVisible = true
      apiProductEditinfo({
        id:row.id,
        game_id: row.game_id
      }).then(res=>{
        this.form.id = row.id
        this.form.game_id = row.game_id
        this.form.account = res.data.game_account
        this.form.pwd = res.data.game_pwd
        this.form.re_pwd = res.data.game_pwd
        this.form.se_pwd = res.data.game_second_pwd
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiProductAccount({
            id:this.form.id,
            game_id:this.form.game_id,
            game_account:this.form.account,
            game_pwd:this.form.pwd,
            game_second_pwd:this.form.se_pwd,
          }).then(res=>{
            this.$message({
              type:"success",
              center:true,
              message:"修改成功"
            })
            this.dialogFormVisible = false
            this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
          }).catch(err=>{
            this.$message({
              type:"error",
              center:true,
              message:err.msg
            })
          })
        }
      })
    },
    submitPForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiPremiumEdit({
            id:this.premiumSetForm.id,
            game_id:this.premiumSetForm.game_id,
            status:this.premiumSetForm.is_premium,
          }).then(res=>{
            this.$message({
              type:"success",
              center:true,
              message:"修改成功"
            })
            this.premiumSet = false
            this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
          }).catch(err=>{
            this.$message({
              type:"error",
              center:true,
              message:err.msg
            })
          })
        }
      })
    },

    //分页监听事件
    handleCurrentChange(val) {
      this.currentPage = val
      this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
    },
    //顶一顶
    productGore(data){
      apiProductGore({
        game_id:data.game_id,
        product_id:data.id,
      }).then(res=>{
        this.$message({
          type:"success",
          center:true,
          message:"顶一顶操作成功"
        })
      }).catch(err=>{
        this.$message({
          type:"error",
          center:true,
          message:err.msg
        })
      })
    },
    //下架
    productOff(data){
      this.$alert('是否确认下架？', '注意', {
        confirmButtonText: '确认',
        confirmButtonClass:'agree_confirm_btn',
        customClass: 'agree_confirm',
        callback: action => {
          if(action === "confirm"){
            apiProductOff({
              game_id:data.game_id,
              id:data.id,
            }).then(res=>{
              this.$message({
                type:"success",
                center:true,
                message:'下架成功'
              })
              this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
            }).catch(err=>{
              this.$message({
                type:"error",
                center:true,
                message:err.msg
              })
            })
          }
        }
      });
    },
    //重新上架
    relist(data){
      this.$alert('是否确认重新上架？', '注意', {
        confirmButtonText: '确认',
        confirmButtonClass:'agree_confirm_btn',
        customClass: 'agree_confirm',
        callback: action => {
          if(action === "confirm"){
            apiProductOn({
              game_id:data.game_id,
              id:data.id,
            }).then(res=>{
              this.$message({
                type:"success",
                center:true,
                message:'上架成功'
              })
              this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
            }).catch(err=>{
              this.$message({
                type:"error",
                center:true,
                message:err.msg
              })
            })
          }
        }
      });
    },
    //议价设置
    premiumSetClick(data){
      this.premiumSet = true
      this.premiumSetForm.is_premium = data.is_premium+''
      this.premiumSetForm.id = data.id
      this.premiumSetForm.game_id = data.game_id
    },
    //游戏筛选 下拉框监听事件
    selectGameChange(event){
      this.currentPage = 1
      this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
    },
    //时间筛选 下拉框监听事件
    selectTimeChange(value){
      this.currentPage = 1
      this.value = value
      this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
    },
    //关键字搜索
    clickMatch(){
      this.currentPage = 1
      this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
    },
    /**
     * 获取 数据 列表
     * @param type    选择时间 下拉框
     * @param game_id 选择游戏 下拉框
     * @param status  tabbar 0～5
     * @param match   关键字
     * @param page   分页
     */
    getDataList(type,game_id,status,match,page){
      apiSellerAccountList({
        type:type,
        game_id:game_id,
        status:status,
        match:match,
        page:page
      }).then(res=>{
        this.totalCount = res.data.total
        this.tableData = res.data.list
      })
    },
    //初始化数据
    onLoad(){
      //获取游戏列表
      apiGameList({
      }).then(res=>{
        res.data.forEach((item,index)=>{
          let options = new Object()
          options.value = item.game_id
          options.label = item.game_name
          this.selectGame.push(options)
        })
      })
      //获取 账号列表
      apiSellerAccountList({
      }).then(res=>{
        this.totalCount = res.data.total
        this.tableData = res.data.list
      })
    },

    //详情页返回按钮
    backClick(){
      this.info = false
      this.getDataList(this.value,this.selectGameValue.value,this.selectItemIndex,this.match,this.currentPage)
    }
  },
  mounted () {
    //初始化数据
    if(typeof(this.$route.params.selectItem) !== 'undefined'){
      this.clickItem(this.$route.params.selectItem,this.$route.params.selectItemIndex)
    }else{
      this.onLoad()
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<!-- 弹框 -->
<style lang="less">
  .agree_confirm{
  width: 386px !important;
  height: 232px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;
  .el-message-box__header,.el-message-box__content,.el-message-box__btns{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .el-message-box__title{
    margin-top: 38px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }
  .el-message-box__container{
    margin-top: 30px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }
  .agree_confirm_btn{
    margin-top: 36px;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }
  .agree_confirm_btn:hover{
    background: #FFDB51 !important;
  }
}
  .change_price_seller{
    width: 500px !important;
    height: 250px !important;
    background: #FFFFFF !important;
    border-radius: 10px !important;
    padding: 0;
    border: none;
    .el-message-box__header>.el-message-box__title{
      display: none;
    }
    .el-message-box__header,.el-message-box__content{
      display: flex;
      flex-direction: column;
      padding: 0 0 0 70px;
    }
    .el-message-box__btns{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
    .el-message-box__title{
      margin-top: 38px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #5B5B5B;
      line-height: 28px;
    }
    .el-message-box__container{
      margin-top: 34px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 22px;
    }
    .change_price_btn{
      margin-top: 40px;
      padding: 0;
      width: 200px;
      height: 40px;
      line-height: 40px;
      background: #FFDB51 !important;
      border-radius: 28px;
      border: none;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #000000 !important;
    }
    .change_price_btn:hover{
      background: #FFDB51 !important;
    }
    .confirm_content{
      display: flex;
      flex-direction: column;
    }
    .confirm_original_price{
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FC4A00;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .confirm_modify_bid{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .confirm_modify_bid_text{
      font-size: 20px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 28px;
    }
    .confirm_modify_bid_input{
      width: 210px;
      height: 40px;
      background: #F9F9F9;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      padding: 0 20px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      line-height: 22px;
    }
    .confirm_modify_bid_input::-webkit-input-placeholder {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #D1D1D1;
    }
  }
  .change_account{
    width: 500px !important;
    height: 250px !important;
    background: #FFFFFF !important;
    border-radius: 10px !important;
    padding: 0;
    border: none;
    .el-message-box__header>.el-message-box__title{
      display: none;
    }
    .el-message-box__header,.el-message-box__content{
      display: flex;
      flex-direction: column;
      padding: 0 0 0 70px;
    }
    .el-message-box__btns{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
    .el-message-box__title{
      margin-top: 38px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #5B5B5B;
      line-height: 28px;
    }
    .el-message-box__container{
      margin-top: 34px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 22px;
    }
    .change_account_btn{
      margin-top: 40px;
      padding: 0;
      width: 200px;
      height: 40px;
      line-height: 40px;
      background: #FFDB51 !important;
      border-radius: 28px;
      border: none;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #000000 !important;
    }
    .change_account_btn:hover{
      background: #FFDB51 !important;
    }
    .confirm_content{
      display: flex;
      flex-direction: column;
    }
    .modify_account{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 0 20px 0;
    }
    .modify_account_text{
      font-size: 20px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 28px;
    }
    .modify_account_input{
      width: 210px;
      height: 40px;
      background: #F9F9F9;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      padding: 0 20px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      line-height: 22px;
    }
    .modify_account_input::-webkit-input-placeholder {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #D1D1D1;
    }
    .modify_pwd{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .modify_pwd_text{
      font-size: 20px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 28px;
    }
    .modify_pwd_input{
      width: 210px;
      height: 40px;
      background: #F9F9F9;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      padding: 0 20px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      line-height: 22px;
    }
    .modify_pwd_input::-webkit-input-placeholder {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #D1D1D1;
    }
  }
  .sellPopconfirm{
    .el-popconfirm{
      .el-popconfirm__action{
        .el-button{
          span{
            color: #FCAD13;
            font-family: PingFangSC-Light, PingFang SC;
          }
        }
        .el-button--productOff{
          border: none;
          background: #FCAD13;
          span{
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .refuse_confirm{
    width: 500px !important;
    height: 300px !important;
    background: #FFFFFF !important;
    border-radius: 10px !important;
    padding: 0;
    border: none;
    .el-message-box__header>.el-message-box__title{
      display: none;
    }
    .el-message-box__header,.el-message-box__content{
      display: flex;
      flex-direction: column;
      padding: 0 0 0 70px;
    }
    .el-message-box__btns{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
    .el-message-box__title{
      margin-top: 38px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #5B5B5B;
      line-height: 28px;
    }
    .el-message-box__container{
      margin-top: 34px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 22px;
    }
    .refuse_confirm_btn{
      margin-top: 20px;
      padding: 0;
      width: 200px;
      height: 40px;
      line-height: 40px;
      background: #FFDB51 !important;
      border-radius: 28px;
      border: none;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #000000 !important;
      justify-content: center;
    }
    .refuse_confirm_btn:hover{
      background: #FFDB51 !important;
    }
    .confirm_content{
      display: flex;
      flex-direction: column;
    }
    .confirm_original_price{
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FC4A00;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .confirm_my_bid{
      font-size: 20px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .confirm_modify_bid{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .confirm_modify_bid_text{
      font-size: 20px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 28px;
    }
    .confirm_modify_bid_input{
      width: 210px;
      height: 40px;
      background: #F9F9F9;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      padding: 0 20px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      line-height: 22px;
    }
    .confirm_modify_bid_tips{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #BFBFBF;
      line-height: 22px;
      margin-left: 35px;
    }
    .confirm_modify_bid_input::-webkit-input-placeholder {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #D1D1D1;
    }
  }

  .btn_ground_popover{
    .popoverBtnList{
      div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 100;
        color: #8F8F8F;
        line-height: 20px;
        padding: 2px 0;
        border-bottom: 1px solid #F5F5F5;
      }
      div:last-of-type{
        border: none;
      }
      div:hover{
        color: #FCAD13;
      }
    }
  }
</style>
<style lang='less' scoped>
  #seller{
    padding: 0 22px;
    display: flex;
    flex-direction: column;
    .tabbar{
      margin-top: 22px;
      height: 58px;
      background: #F4F4F4;
      border-radius: 10px;
      padding: 0 45px;
      display: flex;
      flex-direction: row;
      align-items: center;
      div{
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #5B5B5B;
        margin-right: 69px;
        cursor: pointer;
      }
      .isAcitve{
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #5B5B5B;
        padding-bottom: 6px;
        background: url("../../../assets/image/PersonalCenter/Seller/selectItem.png") no-repeat;
        background-position:bottom;
      }
      div:last-of-type{
        margin: 0;
      }
    }
    .filter{
      margin: 27px 0 43px 0;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .select_ground{
        display: flex;
        flex-direction: row;
        /deep/.el-input__inner{
          width: 180px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #DCDFE6;
        }
        /deep/.el-input.is-focus .el-input__inner{
          border: 1px solid #FCAD13;
        }
        /deep/.popper__arrow{
          left: 155px !important;
        }
        /deep/.el-select-dropdown__item.selected{
          color: #606266;
          font-weight: 400;
        }
        .select_data{
          margin-right: 22px;
        }
        .select_game{

        }
      }
      .filter_search{
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        .filter_search_input{
          width: 160px;
          height: 40px;
          background: #F4F4F4;
          border-radius: 10px;
          border: none;
          padding: 0 120px 0 20px;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
        }
        .filter_search_input::-webkit-input-placeholder{
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #CECECE;
        }
        .filter_search_btn{
          position: absolute;
          top: 0;
          left: 200px;
          width: 100px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: linear-gradient(180deg, #5182FF 0%, #434ED6 100%);
          border-radius: 10px;
          font-size: 20px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
    .product_list{
      /deep/.el-table th{
        height: 50px;
        background: #F4F4F4;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #5B5B5B;
      }
      /deep/.el-table th>.cell{
        text-align: center;
      }
      /deep/.el-table .cell{
        padding: 0;
        text-align: center;
      }
      /deep/.el-table td, .el-table th{
        padding: 0;
      }
      //状态
      /deep/.el-tag{
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        background: #D8F1FC;
        color: #16559D;
      }
      /deep/.el-tag.el-tag--danger{
        padding: 0 2px;
        background: #FCD8D8;
        color: #B90101;
      }
      /deep/.el-tag.el-tag--info{
        background: #F0F0F0;
        color: #BFBFBF;
      }
      /deep/.el-tag.el-tag--success{
        background: #D8FCDE;
        color: #389D16;
      }
      /deep/.el-tag.el-tag--warning{
        background: #FFF2D9;
        color: #FCAD13;
      }
      /deep/.el-table__row:hover{
        cursor: pointer;
        td{
          background: #fffcf2;
        }
        .infoBtn{
          display: flex;
        }
      }
      //商品信息
      .product{
        height: 170px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .product__img{
          height: 130px;
          margin-right: 20px;
          width: 130px;
          //background: lightpink;
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
          /deep/.el-image{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
        .product_info{
          width: 420px;
          display: flex;
          flex-direction: column;
          height: 130px;
          justify-content: space-around;
          .product_info_top{
            flex-shrink: 0;
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #5B5B5B;
            line-height: 22px;
            height: 66px;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            margin-bottom: 10px;
            span{
              padding: 1px 7px;
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #434ED6;
              line-height: 20px;
              border: 1px solid #434ED6;
              border-radius: 15px;
            }
          }
          .product_info_mid{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            text-align: left;
            .gaming_zone{
              width: 33.33%;
              font-size: 13px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #8F8F8F;
              line-height: 22px;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              span{
                font-size: 13px;
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #BFBFBF;
                line-height: 22px;
              }
            }
          }
          .product_info_bot{
            text-align: left;
            font-size: 13px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #BFBFBF;
            line-height: 22px;
            display: flex;
            align-items: center;
            flex-direction: row;
            #release_date{
              margin-right: 44px;
            }
            #browse{
              display: flex;
              flex-direction: row;
              align-items: center;
              img{
                margin: 0 3px 0 0;
                width: 18px;
                height: 20px;
                object-fit: cover;
              }
              margin-right: 44px;
            }
            #collect{
            }
          }
        }
      }
      //金额
      .product_price{
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #FC4A00;
      }
      //买家出价
      .buyer_bid{
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #BFBFBF;
      }

      .infoBtn{
        background: #fff0bf;
        min-height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffaf43;
        font-size: 30px;
        cursor: pointer;
        display: none;
      }
      //分页
      .pages{
        height: 94px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      //暂无数据
      .no_data{
        padding: 36px;
        img{
          width: 196px;
          height: 151px;
          object-fit: cover;
        }
      }
    }
    /deep/.el-dialog{
      border-radius: 10px;
    }
    /deep/.el-dialog__title{
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #5B5B5B;
      line-height: 28px;
    }
    /deep/.el-dialog__body{
      padding: 30px 70px 0;
    }
    /deep/.is-checked .el-radio__inner {
      border-color: #FCAD13;
      background: #FCAD13;
    }
    /deep/.el-radio>.el-radio__label{
      font-size: 14px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #606266;
      line-height: 14px;
    }
    /deep/.el-radio.is-checked>.el-radio__label{
      color: #FCAD13;
    }
    /deep/.el-form-item__label{
      font-size: 20px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 40px;
    }
    .premiumSet /deep/.el-form-item__label{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
    }
    .dialogInput /deep/.el-input__inner{
      width: 250px;
      height: 40px;
      background: #F9F9F9;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
    }
    .dialogInput /deep/.el-input__inner::-webkit-input-placeholder{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #D1D1D1;
    }

    /deep/.el-button{
      width: 200px;
      height: 40px;
      background: #FFDB51;
      border-radius: 28px;
      border: none;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    /deep/.el-dialog__footer{
      padding: 10px 20px 40px;
    }
  }
</style>
