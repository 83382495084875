<template>
  <div class="sellInfo">
    <div class="header">
      <div class="title">
        <img src="@/assets/image/PersonalCenter/Seller/infoHeadIcon.png" alt="">
        商品详情
      </div>
      <div class="btnGroup">
        <share v-if="productInfo.status===1" :game_id="productInfo.game_id" :game_name="productInfo.game_name" :name="productInfo.name" :id="productInfo.id" ></share>
        <div class="back" @click="back">返回</div>
      </div>

    </div>
    <div class="actionBar">
      <div class="head">
        <div class="title">
          <span></span>商品状态
        </div>
        <div class="delBtn" v-if="productInfo.status===5" @click="delDialog = true"><i class="el-icon-delete"></i>删除商品</div>
      </div>
      <div class="box">
        <div class="status">
          <div class="label">当前状态</div>
          <div class="value">
            <el-tag v-if="productInfo.status===1" type="warning" size="medium">在售</el-tag>
            <el-tag v-else-if="productInfo.status===6" type="warning" size="medium">出售中</el-tag>
            <el-tag v-else-if="productInfo.status===2" type="success" size="medium">已售</el-tag>
            <el-tag v-else-if="productInfo.status===3" type="info" size="medium">已下架</el-tag>
            <el-tag v-else-if="productInfo.status===4" size="medium">审核中</el-tag>
            <el-tag v-else-if="productInfo.status===5" type="danger" size="medium">审核失败</el-tag>
          </div>
        </div>
        <div class="btnGroup">
          <div class="btn onShelf" v-if="productInfo.is_user_xiajia == '1' && productInfo.status===3"
               @click="tipDialogShow('onShelf')">重新上架
          </div>
          <div class="btn onShelf" v-if="productInfo.status===5" @click="handleEditAccount">修改密码</div>
<!--          <div class="btn cusSerBtn" v-if="productInfo.status!==1" @click="cusSerClick">咨询客服</div>-->
          <div class="operating" v-if="productInfo.status ===1" @click="tipDialogShow('gore')">
            <img src="@/assets/image/PersonalCenter/Seller/operating1.png" alt="">
            顶一顶
          </div>
          <div class="operating" v-if="productInfo.status ===1" @click="tipDialogShow('productOff')">
            <img src="@/assets/image/PersonalCenter/Seller/operating2.png" alt="">
            下架商品
          </div>
          <div class="operating" v-if="productInfo.status ===1" @click="premiumSetClick">
            <img src="@/assets/image/PersonalCenter/Seller/operating3.png" alt="">
            求降设置
          </div>
        </div>
      </div>
    </div>
    <div class="price">
        <div class="text">
          <div class="label">商品价格：</div>
          <div class="value">¥{{ productInfo.price }}</div>
        </div>
        <div class="btn" @click="editPrice" v-if="productInfo.status === 1 || productInfo.status === 5">
          修改价格
        </div>
    </div>
    <div class="info">
      <div class="basicInfo">
        <div class="time">发布时间：{{ productInfo.add_time|moments }}</div>
        <div class="hot">热度：{{ productInfo.click }}</div>
      </div>
      <div class="name">
        <div class="text">
          <span class="gameName">{{ productInfo.game_name }}</span>
          {{ productInfo.name }}
        </div>
      </div>
      <div class="otherInfo">
        <div class="tags">
          <img v-if="productInfo.is_sign === 1" src="@/assets/image/AllGame/isSign.png" alt="">
          <img v-if="productInfo.bind_psn === 1" src="@/assets/image/AllGame/bindPsn.png" alt="">
          <img v-if="productInfo.bind_tap === 1" src="@/assets/image/AllGame/bindTap.png" alt="">
        </div>
        <div class="zone">
          <div class="zoneItem" v-for="(item,index) in productInfo.category" :key="index">
            <div class="itemLabel">{{ item.name }}：</div>
            <div class="itemValue">{{ item.value }}</div>
          </div>
        </div>

      </div>
      <div class="modify" v-if="productInfo.status === 1">
        点击后跳转客服联系修改
        <i class="el-icon-caret-right"></i>
        <div class="btn" @click="tipDialogShow('cusSer')">修改详情</div>
      </div>

    </div>
    <div class="imgInfo"
         v-if="(productInfo.cdata && productInfo.cdata!='<p></p>') || (productInfo.content && productInfo.content!='<p></p>')">
      <div class="title">详情图片</div>
      <div class="imgGroup">
        <div v-if="productInfo.cdata && productInfo.cdata!='<p></p>'" v-html="productInfo.cdata"></div>
        <div v-if="productInfo.content && productInfo.content!='<p></p>'" v-html="productInfo.content"></div>
      </div>
      <div class="modify" v-if="productInfo.status === 1">
        点击后跳转客服联系修改
        <i class="el-icon-caret-right"></i>
        <div class="btn" @click="tipDialogShow('cusSer')">修改详情</div>
      </div>
    </div>
    <!--  提示确认框  -->
    <el-dialog
      :visible.sync="tipDialog"
      custom-class="tipDialog"
      :show-close="false"
      width="358px"
      top="35vh"
      center>
      <div id="tipDialog">
        <div class="title">提示</div>
        <div class="text">
          {{ tipObj.text }}
        </div>
        <div class="btn" @click="tipObj.$Function">{{ tipObj.btn }}</div>
        <img class="close" src="../../../assets/image/AccountDeal/close.png" alt="" @click="tipDialog = false">
      </div>
    </el-dialog>
    <!--  删除记录确认框  -->
    <el-dialog
      :visible.sync="delDialog"
      custom-class="tipDialog"
      :show-close="false"
      width="358px"
      top="35vh"
      center>
      <div id="delDialog">
        <div class="title">提示</div>
        <div class="text">
          是否确认删除商品信息
        </div>
        <div class="btnGroup">
          <div class="btn" @click="delDialog = false">取消</div>
          <div class="btn" @click="delect">确认</div>
        </div>
        <img class="close" src="../../../assets/image/AccountDeal/close.png" alt="" @click="delDialog = false">
      </div>
    </el-dialog>
    <!--  修改账号密码  -->
    <el-dialog custom-class="changeAccount"
               title="修改账号密码"
               :visible.sync="dialogFormVisible"
               width="500px"
               top="27vh"
               center>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="游戏账号:" class="dialogInput" :label-width="formLabelWidth" prop="account">
          <el-input v-model="form.account" autocomplete="off" placeholder="请输入游戏账号"></el-input>
        </el-form-item>
        <el-form-item label="游戏密码:" class="dialogInput" :label-width="formLabelWidth" prop="pwd">
          <el-input v-model="form.pwd" autocomplete="off" placeholder="请输入游戏密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" class="dialogInput" :label-width="formLabelWidth" prop="re_pwd">
          <el-input v-model="form.re_pwd" autocomplete="off" placeholder="请再次输入游戏密码"></el-input>
        </el-form-item>
        <el-form-item v-if="form.game_id == '13'" label="营地账号:" class="dialogInput" :label-width="formLabelWidth"
                      prop="camp_account">
          <el-input v-model="form.camp_account" autocomplete="off" placeholder="请输入营地账号"></el-input>
        </el-form-item>
        <el-form-item v-if="form.game_id == '8'" label="二级密码:" class="dialogInput" :label-width="formLabelWidth"
                      prop="se_pwd">
          <el-input v-model="form.se_pwd" autocomplete="off" placeholder="请输入二级密码"></el-input>
        </el-form-item>
        <el-form-item v-if="form.game_id == '10' || form.game_id == '15'" label="角色密码:" class="dialogInput"
                      :label-width="formLabelWidth" prop="se_pwd">
          <el-input v-model="form.se_pwd" autocomplete="off" placeholder="请输入角色登录密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">确认修改</el-button>
      </div>
    </el-dialog>
    <!--  求降价设置  -->
    <el-dialog title="求降价设置"
               custom-class="premiumSet"
               :visible.sync="premiumSet"
               width="500px"
               top="27vh"
               center>
      <el-form :model="premiumSetForm" ref="premiumSetForm">
        <el-form-item label="是否接受议价:" class="premiumSet" label-width="140px" prop="is_premium">
          <el-radio-group v-model="premiumSetForm.is_premium">
            <el-radio label="1">接受议价</el-radio>
            <el-radio label="0">不接受议价</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPForm('premiumSetForm')">确认修改</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {
  apiPremiumEdit,
  apiProductAccount,
  apiProductGore,
  apiProductInfo,
  apiProductOff,
  apiProductOn,
  apiProductPrice,
  apiProductEditinfo, apiProductDel
} from "@/request/API";
import share from "@/components/share"

export default {
  name: "sellInfo",
  props: {
    back: {
      type: Function,
      default: null
    },
    product: {
      type: Object,
      default: null
    },
  },
  components:{
    share
  },
  data() {
    var valiPwd = (rule, value, callback) => {
      if (value !== this.form.pwd) {
        callback(new Error('两次密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      productInfo: {},
      //提示框弹窗
      tipDialog: false,
      //删除提示框弹窗
      delDialog: false,
      tipObj: {},
      //修改账号密码
      dialogFormVisible: false,
      form: {
        id: '',
        game_id: '',
        account: '',
        pwd: '',
        re_pwd: '',
        se_pwd: '',
        camp_account: '',
      },
      rules: {
        account: [
          {required: true, message: '请输入游戏账号', trigger: 'blur'},
        ],
        pwd: [
          {required: true, message: '请输入游戏密码', trigger: 'blur'},
        ],
        re_pwd: [
          {required: true, message: '请再次输入游戏密码', trigger: 'blur'},
          {validator: valiPwd, trigger: 'blur'}
        ],
        camp_account: [
          {required: true, message: '请输入营地账号', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{6,11}$/, message: '营地账号格式错误'}
        ]
      },
      formLabelWidth: '120px',

      premiumSet: false,
      premiumSetForm: {
        is_premium: '',
        id: '',
        game_id: '',
      },

    }
  },
  methods: {
    /**
     * 获取商品信息
     */
    getProductInfo() {
      apiProductInfo({
        game_id: this.product.game_id,
        id: this.product.id,
      }).then(res => {
        this.productInfo = res.data
      })
    },

    /**
     * 提示弹窗
     * @param type
     */
    tipDialogShow(type) {
      this.tipDialog = true
      switch (type) {
        case 'cusSer':
          this.tipObj.text = '修改该内容需要联系客服修改'
          this.tipObj.btn = '联系客服'
          this.tipObj.$Function = this.cusSerClick
          break;
        case 'onShelf':
          this.tipObj.text = '是否确认重新上架?'
          this.tipObj.btn = '确认'
          this.tipObj.$Function = this.relist
          break;

        case 'productOff':
          this.tipObj.text = '是否确认下架?'
          this.tipObj.btn = '确认'
          this.tipObj.$Function = this.productOff
          break;

        case 'gore':
          this.tipObj.text = '顶一顶后账号将会被顶到平台商品前列，有利于商品曝光～'
          this.tipObj.btn = '确认顶一顶'
          this.tipObj.$Function = this.productGore
          break;

      }
    },
    /**
     * 联系客服
     */
    cusSerClick() {
      if(this.productInfo.game_id == 10){
        let routeData = this.$router.resolve({
          name: 'CustomerSer',
          query: {
            game_id: this.productInfo.game_id
          }
        })
        window.open(routeData.href, '_blank')
      } else{
        this.$accessLink(this.productInfo.game_customer)
        window.open(`${this.productInfo.game_customer}?u_cust_id=${this.$store.state.userData.user_id}&u_cust_name=${this.$store.state.userData.nickname}`, "_blank")
      }
      this.tipDialog = false
    },
    /**
     * 重新上架
     */
    relist() {
      apiProductOn({
        game_id: this.productInfo.game_id,
        id: this.productInfo.id,
      }).then(res => {
        this.$message({
          type: "success",
          center: true,
          message: '上架成功'
        })
        this.tipDialog = false
        this.getProductInfo()
      }).catch(err => {
        this.$message({
          type: "error",
          center: true,
          message: err.msg
        })
      })
    },

    /**
     * 修改价格
     */
    editPrice() {
      const h = this.$createElement;
      this.$msgbox({
        title: '',
        message: h('div', {class: 'confirm_content'}, [
          h('div', {class: 'confirm_original_price'}, '当前价格：' + this.productInfo.price + ''),
          h('div', {class: 'confirm_modify_bid'}, [
            h('div', {class: 'confirm_modify_bid_text'}, '修改出价：'),
            h('input', {
              class: 'confirm_modify_bid_input',
              attrs: {placeholder: "输入修改后的价格", id: "confirm_modify_bid_input"}
            }),
          ]),
        ]),
        confirmButtonText: '确认修改',
        confirmButtonClass: 'change_price_btn',
        customClass: 'change_price_seller',
        closeOnClickModal: false,
        showClose: true,
        callback: action => {
          if (action === "confirm") {
            apiProductPrice({
              id: this.productInfo.id,
              game_id: this.productInfo.game_id,
              price: document.getElementById("confirm_modify_bid_input").value
            }).then(res => {
              this.$message({
                message: '修改成功',
                center: true,
                type: 'success'
              })
              this.getProductInfo()
            }).catch(err => {
              this.$message({
                message: err.msg,
                center: true,
                type: 'error'
              })
            })
          }
          document.getElementById("confirm_modify_bid_input").value = ''
        }
      });
    },

    /**
     * 顶一顶
     */
    productGore() {
      apiProductGore({
        game_id: this.productInfo.game_id,
        product_id: this.productInfo.id,
      }).then(res => {
        this.tipDialog = false
        this.$message({
          type: "success",
          center: true,
          message: "顶一顶操作成功"
        })
      }).catch(err => {
        this.$message({
          type: "error",
          center: true,
          message: err.msg
        })
      })
    },
    /**
     * 下架
     */
    productOff() {
      apiProductOff({
        game_id: this.productInfo.game_id,
        id: this.productInfo.id,
      }).then(res => {
        this.$message({
          type: "success",
          center: true,
          message: '下架成功'
        })
        this.tipDialog = false
        this.getProductInfo()
      }).catch(err => {
        this.$message({
          type: "error",
          center: true,
          message: err.msg
        })
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiProductAccount({
            id: this.form.id,
            game_id: this.form.game_id,
            game_account: this.form.account,
            game_pwd: this.form.pwd,
            game_second_pwd: this.form.se_pwd,
            camp_account: this.form.camp_account,
          }).then(res => {
            this.$message({
              type: "success",
              center: true,
              message: "修改成功"
            })
            this.dialogFormVisible = false
            this.getProductInfo()
          }).catch(err => {
            this.$message({
              type: "error",
              center: true,
              message: err.msg
            })
          })
        }
      })
    },
    submitPForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiPremiumEdit({
            id: this.premiumSetForm.id,
            game_id: this.premiumSetForm.game_id,
            status: this.premiumSetForm.is_premium,
          }).then(res => {
            this.$message({
              type: "success",
              center: true,
              message: "修改成功"
            })
            this.premiumSet = false
            this.getProductInfo()
          }).catch(err => {
            this.$message({
              type: "error",
              center: true,
              message: err.msg
            })
          })
        }
      })
    },

    /**
     * 议价设置
     */
    premiumSetClick() {
      this.premiumSet = true
      this.premiumSetForm.is_premium = this.productInfo.is_premium + ''
      this.premiumSetForm.id = this.productInfo.id
      this.premiumSetForm.game_id = this.productInfo.game_id
    },

    handleEditAccount() {
      this.dialogFormVisible = true
      apiProductEditinfo({
        id: this.productInfo.id,
        game_id: this.productInfo.game_id
      }).then(res => {
        this.form.id = this.productInfo.id
        this.form.game_id = this.productInfo.game_id
        this.form.account = res.data.game_account
        this.form.pwd = res.data.game_pwd
        this.form.re_pwd = res.data.game_pwd
        this.form.se_pwd = res.data.game_second_pwd
        this.form.camp_account = res.data.camp_account
      })
    },
    /**
     * 审核失败 删除数据
     */
   async delect(){
    await apiProductDel({
        game_id:this.product.game_id,
        id:this.product.id,
      }).then(res=>{
        this.$message({
          type: "success",
          center: true,
          message: '删除成功'
        })
      })
      this.delDialog = false
      this.back()
    }

  },
  mounted() {
    this.getProductInfo()
  }
}
</script>

<style lang="less">
.tipDialog {
  border-radius: 10px;

  .el-dialog__header, .el-dialog__body {
    padding: 0 !important;
  }

  #tipDialog {
    position: relative;
    padding: 26px 34px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #353535;
    }

    .text {
      width: 253px;
      margin: 22px 0;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #696969;
      text-align: center;
    }

    .btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: CENTER;
      color: #FFFFFF;
      background: #434ED6;
      border-radius: 21px;
      cursor: pointer;
    }

    .close {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -40px;
      left: calc(50% - 15px);
      cursor: pointer;
    }
  }
  #delDialog {
    position: relative;
    padding: 26px 34px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #353535;
    }

    .text {
      width: 253px;
      margin: 22px 0;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #696969;
      text-align: center;
    }

    .btnGroup{
      display: flex;
      align-items: center;

      .btn {
        width: 140px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #FFFFFF;
        background: #434ED6;
        border-radius: 21px;
        cursor: pointer;
        border: 1px solid #434ED6;
      }
      .btn:first-of-type{
        background: #FFFFFF;
        border: 1px solid #000000;
        margin: 0 15px 0 0;
      }
    }


    .close {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -40px;
      left: calc(50% - 15px);
      cursor: pointer;
    }
  }
}
</style>

<style scoped>
.imgGroup >>> p {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.imgGroup >>> p > img {
  width: 286px;
  height: 160px;
  border-radius: 10px;
  margin: 0 10px 10px 0;
}
</style>

<style scoped lang="less">
.sellInfo {
  background: #f4f4f4;

  .header {
    height: 70px;
    background: linear-gradient(135deg, #17327A, #31376E 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 0 28px;
    .btnGroup{
      display: flex;
      align-items: center;
      .share{
        /deep/.shareBtn{
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
        /deep/.pxb7-share,/deep/.pxb7-share-full{
          color: #FFFFFF !important;
        }
      }
    }

    .title, .back {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #ffffff;

      img {
        margin: 0 12px 0 0;
      }
    }

    .back {
      cursor: pointer;
    }
  }

  .actionBar {
    background: #FFFFFF;
    padding: 22px 27px 0;



    .box {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .status {
        display: flex;
        align-items: center;

        .label {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #000000;
          margin: 0 20px 0 0;
        }

        .value {
          //状态
          /deep/ .el-tag {
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            background: #D8F1FC;
            color: #16559D;
          }

          /deep/ .el-tag.el-tag--danger {
            padding: 0 2px;
            background: #FCD8D8;
            color: #B90101;
          }

          /deep/ .el-tag.el-tag--info {
            background: #F0F0F0;
            color: #BFBFBF;
          }

          /deep/ .el-tag.el-tag--success {
            background: #D8FCDE;
            color: #389D16;
          }

          /deep/ .el-tag.el-tag--warning {
            background: #FFF2D9;
            color: #FCAD13;
          }
        }

      }

      .btnGroup {
        display: flex;
        align-items: center;

        .btn {
          cursor: pointer;
          width: 170px;
          height: 40px;
          line-height: 40px;
          background: #434ED6;
          border: 1px solid #434ED6;
          border-radius: 28px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: CENTER;
          color: #FFFFFF;
        }

        .onShelf {
          margin: 0 18px 0 0;
          background: #FFFFFF;
          border: 1px solid #000000;
        }

        .operating {
          margin: 0 14px 0 0;
          width: 200px;
          height: 50px;
          border: 1px solid #000000;
          border-radius: 10px;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 16.18px;
            object-fit: cover;
            margin: 0 6px 0 0;
          }
        }
      }
    }
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #000000;
      display: flex;
      align-items: center;
      span{
        display: inline-block;
        width: 9px;
        height: 20px;
        background: #000000;
        margin: 0 12px 0 0;
      }
    }
    .delBtn{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #000000;
      cursor: pointer;
    }
  }
  .price {
    padding: 0 40px 0 29px;
    background: #FFFFFF;
    height: 80px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #8f8f8f;
        margin: 0 10px 0 0;
      }

      .value {
        font-size: 30px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #fc4a00;
      }
    }

    .btn {
      width: 170px;
      height: 40px;
      line-height: 40px;
      background: #434ED6;
      border-radius: 28px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: CENTER;
      color: #FFFFFF;
      cursor: pointer;
    }
  }

  .info {
    background: #FFFFFF;
    padding: 20px;
    margin: 0 0 20px;

    .basicInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 10px;
      border-bottom: 1px solid #e2e2e2;

      div {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #8c8c8c;
        padding: 0 20px 0 9px;
      }

      .hot {
        font-family: PingFang SC, PingFang SC-Light;
        font-weight: 300;
        color: #5b5b5b;
      }

    }

    .name {
      border-bottom: 1px solid #e2e2e2;
      padding: 16px 0 13px;

      .text {
        padding: 0 9px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #5b5b5b;
        line-height: 34px;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;

        .gameName {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #434ED6;
          padding: 2px 13px;
          border: 1px solid #434ED6;
          border-radius: 20px;
          margin: 0 10px 0 0;
        }
      }

    }

    .otherInfo {
      padding: 20px 0 10px;

      .tags {
        display: flex;
        align-items: center;
        padding: 0 9px;
        margin: 0 0 20px;

        img {
          margin: 0 8px 0 0;
          width: 80px;
          height: 20px;
        }
      }

      .zone {
        padding: 0 9px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .zoneItem {
          display: flex;
          align-items: center;
          width: 33%;
          margin: 0 0 10px;

          div {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #8f8f8f;
          }

          .itemLabel {
            min-width: 84px;
          }

          .itemValue {
            color: #5b5b5b;
            height: 24px;
            line-height: 24px;
            min-width: 118px;
            text-align: center;
            background: url("../../../assets/image/PersonalCenter/Seller/infoGamingZone.png") no-repeat;
            background-position: 50%;
          }
        }
      }
    }

    .modify {
      border-top: 1px solid #e2e2e2;
      padding: 20px 20px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #bebebe;

      i {
        margin: 0 10px 0 4px;
      }

      .btn {
        width: 170px;
        height: 40px;
        background: #434ED6;
        border-radius: 28px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  .imgInfo {
    padding: 0 29px 80px;
    background: #FFFFFF;

    .title {
      padding: 20px 0 10px;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #000000;
    }

    .imgGroup {
      padding: 0 0 9px;
    }

    .modify {
      border-top: 1px solid #e2e2e2;
      padding: 20px 20px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #bebebe;

      i {
        margin: 0 10px 0 4px;
      }

      .btn {
        width: 170px;
        height: 40px;
        background: #434ED6;
        border-radius: 28px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

}

</style>
