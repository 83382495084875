<template>
  <div class="share_QR_code" v-show="show" @click="close">
    <div class="qrcode" ref="qrCodeDiv"></div>
    <div class="text">
      手机微信扫描二维码即可分享好友
    </div>
    <img class="close" src="@/assets/image/AccountDeal/close.png" alt="" @click="close()">
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
  name: "share_QR_code1",
  props:{
    show:{
      type:Boolean,
      default: false
    },
    shareUrl:{
      type:String,
      default: ''
    }
  },
  data(){
    return {

    }
  },
  mounted() {
    this.getQCode();
  },
  methods:{
    /**
     * 关闭
     */
    close() {
      this.$emit('close')
    },
    /**
     * 生成下载app二维码
     */
    getQCode(){
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.shareUrl,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
  }
}
</script>

<style scoped lang="less">
.share_QR_code{
  width: 100%;
  height: 100%;
  //opacity: 0.73;
  background: rgba(0, 0, 0, 0.73);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .qrcode{
    padding: 13px;
    background: #FFFFFF;
  }
  .text{
    margin: 20px 0;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
  .close{
    width: 30px;
    height: 30px;
  }
}

</style>
