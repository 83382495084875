<template>
  <div class="share">
    <div class="shareBtn" @mouseleave="shareIcon='pxb7-share'" @mouseenter="shareIcon='pxb7-share-full'" @click="share_wechat()">
      <i class="iconfont" :class="shareIcon"></i>
      分享好友
      <!--    <div class="shareList" v-show="shareIcon === 'pxb7-share-full'">-->
      <div class="shareList" style="display: none">
        <div class="shareItem" v-for="(item,index) in shareList" :key="index" @click="item.func">
          <img :src="item.img" alt="">
          {{ item.label }}
        </div>
      </div>
    </div>
    <share_QR_code :show="QR_code" @close="QR_code = false" :share-url="shareUrl"></share_QR_code>
  </div>

</template>

<script>
import share_QR_code from "@/views/AllGame/gameDetails/components/share_QR_code";
export default {
  name: "index",
  props:{
    game_name:{
      type:String,
      default: ''
    },
    name:{
      type:String,
      default: ''
    },
    game_id:{
      type:[String,Number],
      default: ''
    },
    id:{
      type:[String,Number],
      default: ''
    },
  },
  components:{
    share_QR_code
  },
  data(){
    return {
      shareIcon:'pxb7-share',
      shareList:[
        // {label:'QQ好友',img:require('../../assets/image/AllGame/gameDetails/share1.png'),func:this.share_qq},
        {label:'微信好友',img:require('../../assets/image/AllGame/gameDetails/share2.png'),func:this.share_wechat},
        // {label:'QQ空间',img:require('../../assets/image/AllGame/gameDetails/share3.png'),func:this.share_qzone},
        // {label:'新浪微博',img:require('../../assets/image/AllGame/gameDetails/share4.png'),func:this.share_weibo},
      ],
      image:'https://api.pxb7.com/images/product/tymyd83950_thumb.jpg',
      QR_code:false,

      // shareUrl:`https://www.pxb7.com/gamedetails?game_id=${this.game_id}&id=${this.id}`
      shareUrl:`https://m1.pxb7.com/pages/product/detail?game_id=${this.game_id}&id=${this.id}&isShare=1`
    }
  },
  methods:{
    /**
     * 分享到QQ
     * @param event
     */
    share_qq(event) {
      event.preventDefault()
      var _shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?'
      _shareUrl += 'url=' + encodeURIComponent(this.shareUrl)
      _shareUrl += '&title=' + encodeURIComponent(`uu手游游戏代售-${this.game_name}`)
      _shareUrl += `&sharesource=qzone&pics=${this.image}&summary=你的分享描述}&desc=${this.name}`
      window.open(
        _shareUrl,
        '_blank',
        'height=100, width=400',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes'
      )
    },
    /**
     * 分享到QQ空间
     * @param event
     */
    share_qzone(event){
      event.preventDefault()
      var _shareUrl = 'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?'
      _shareUrl += 'url=' + encodeURIComponent(this.shareUrl)
      _shareUrl += '&title=' + encodeURIComponent(`uu手游游戏代售-${this.game_name}`)
      _shareUrl +=  `&sharesource=qzone&pics=${this.image}&summary=${this.name}`
      window.open(
        _shareUrl,
        '_blank',
        'height=100, width=400',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes'
      )
    },
    /**
     * 分享到新浪微博
     * @param event
     */
    share_weibo(event) {
      event.preventDefault()
      var _shareUrl = 'http://service.weibo.com/share/share.php?'
      _shareUrl += 'url=' + encodeURIComponent(this.shareUrl) //参数url设置分享的内容链接
      _shareUrl += '&title=' + encodeURIComponent(this.name) //参数title设置分享的标题
      window.open(
        _shareUrl,
        '_blank',
        'height=100, width=400',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes'
      )
    },

    /**
     * 分享到微信
     */
    share_wechat(){
      this.QR_code = true
    }

  },
}
</script>

<style scoped lang="less">
  .shareBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 4px;
    margin: 0 40px 0 0;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #818181;
    cursor: pointer;
    position: relative;

    i {
      margin: 0 10px 0 0;
      height: 30px;
      font-size: 30px;
      object-fit: contain;
      color: #FFB320 ;
    }
    .shareList{
      position: absolute;
      //bottom:-180px;
      bottom:-60px;
      left: 0;
      width: 140px;
      //height: 160px;
      padding: 10px 0;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.2000);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      .shareItem{
        height: 40px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1F23;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img{
          margin: 0 9px 0 0;
        }
      }
      .shareItem:hover{
        background: #F7F9FA;
      }

    }
  }
</style>
